import { useState } from "react"

import { UpgradeBlockerDialog } from "src/components/FeatureBlockers/UpgradeBlockerDialog"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { IGuestCommunicationRuleTemplate } from "src/data/guestCommunication/types/guestCommunicationTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { DropdownButton } from "src/ui/Button/DropdownButton"
import { DropdownListButton, IOption } from "src/ui/Button/DropdownListButton"

export function GuestCommunicationAddButton({
  templates,
  loading,
}: {
  templates?: IGuestCommunicationRuleTemplate[]
  loading?: boolean
}) {
  const { navigate } = useRouter()
  const { t, langKeys } = useTranslate()

  const guestConnectAvailability = useFeatureAvailability({
    feature: "guest_connect",
  })
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false)

  const dropdownOptions: IOption[] =
    templates?.map((template) => ({
      value: template.id,
      onClick: () =>
        navigate(Routes.OrgGuestComCreateRule.location(template.id)),
      ...template,
    })) || []

  if (!guestConnectAvailability.available) {
    return (
      <>
        <DropdownButton
          displayValue={t(langKeys.add_message)}
          variant="secondary"
          disabled={!guestConnectAvailability.ready}
          onClick={() => {
            setShowUpgradeDialog(true)
          }}
        />

        <UpgradeBlockerDialog
          context="guest_connect"
          open={showUpgradeDialog}
          onClose={() => setShowUpgradeDialog(false)}
        />
      </>
    )
  }

  return (
    <DropdownListButton
      displayValue={t(langKeys.add_message)}
      options={dropdownOptions}
      listSubheader={t(langKeys.guest_connect_choose_template)}
      loading={loading}
    />
  )
}
