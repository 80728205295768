import { useParams } from "react-router-dom"

import { FeatureBlockerText } from "src/components/FeatureBlockers/FeatureBlockerText"
import { GuestCommunicationAddButton } from "src/components/GuestCommunication/GuestCommunicationAddButton"
import {
  CreateRuleDialog,
  EditRuleDialog,
} from "src/components/GuestCommunication/GuestCommunicationDialogs"
import { GuestCommunicationEmptyState } from "src/components/GuestCommunication/GuestCommunicationEmptyState"
import { GuestCommunicationLoading } from "src/components/GuestCommunication/GuestCommunicationLoading"
import { GuestCommunicationTable } from "src/components/GuestCommunication/GuestCommunicationTable"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import {
  useFetchGuestCommunicationRules,
  useFetchGuestCommunicationRuleTemplates,
} from "src/data/guestCommunication/queries/guestCommunicationQueries"
import {
  IGuestCommunicationRule,
  IPostGuestCommunicationRule,
} from "src/data/guestCommunication/types/guestCommunicationTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { MainView } from "src/ui/Layout/MainView"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"

export function GuestCommunication({ orgId }: { orgId: string }) {
  const { t, langKeys } = useTranslate()
  const params = useParams<{ ruleId?: string; ruleTemplateId?: string }>()
  const fetchRuleTemplates = useFetchGuestCommunicationRuleTemplates({})

  const guestConnectAvailability = useFeatureAvailability({
    feature: "guest_connect",
  })

  const fetchRules = useFetchGuestCommunicationRules({
    orgId,
    options: {
      enabled: guestConnectAvailability.available,
    },
  })

  const rulesData = fetchRules?.data || []

  function getRule(ruleId?: string): IGuestCommunicationRule | null {
    if (fetchRules.isInitialLoading || !ruleId) {
      return null
    }
    return rulesData.find((r) => r.id === ruleId) || null
  }

  function getRuleTemplate(
    ruleTemplateId?: string
  ): IPostGuestCommunicationRule | null {
    if (fetchRuleTemplates.isInitialLoading || !ruleTemplateId) {
      return null
    }
    return fetchRuleTemplates.data?.find((r) => r.id === ruleTemplateId) || null
  }

  const rule = getRule(params.ruleId)
  const ruleTemplate = getRuleTemplate(params.ruleTemplateId)

  return (
    <MainView
      title={
        <FeatureBlockerText
          isFeatureAvailable={guestConnectAvailability.available}
          hidden={!guestConnectAvailability.ready}
        >
          <MText variant="heading1">{t(langKeys.guest_connect_title)}</MText>
        </FeatureBlockerText>
      }
      metaTitle={t(langKeys.guest_connect_title)}
      titleBarProps={{
        description: (
          <div>
            {t(langKeys.messaging_description)}{" "}
            <InternalLink to={Routes.MonitoringSettings.location().pathname}>
              {t(langKeys.settings)}
            </InternalLink>
            .
          </div>
        ),
        actionBar: (
          <GuestCommunicationAddButton
            templates={fetchRuleTemplates.data}
            loading={fetchRuleTemplates.isLoading}
          />
        ),
      }}
    >
      {fetchRules.isError && (
        <MBanner type="error">
          {t(langKeys.failed_general_error_try_refreshing_page)}
        </MBanner>
      )}

      <div>
        <GuestCommunicationTable orgId={orgId} rowData={rulesData} />
        {fetchRules.isInitialLoading && <GuestCommunicationLoading />}
      </div>

      {!fetchRules.isInitialLoading && rulesData.length === 0 && (
        <GuestCommunicationEmptyState
          templates={fetchRuleTemplates.data}
          loading={fetchRuleTemplates.isLoading}
        />
      )}

      {!!rule && <EditRuleDialog orgId={orgId} rule={rule} />}
      {!!ruleTemplate && <CreateRuleDialog orgId={orgId} rule={ruleTemplate} />}
    </MainView>
  )
}
