import { MenuItem } from "@material-ui/core"
import { MenuList } from "@material-ui/core"

import { useFetchGuestCommunicationShortcodes } from "src/data/guestCommunication/queries/guestCommunicationQueries"
import { IShortcode } from "src/data/guestCommunication/types/guestCommunicationTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { DropdownButton } from "src/ui/Button/DropdownButton"
import { MCircularProgress } from "src/ui/MCircularProgress/MCircularProgress"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function SelectShortcode({
  onSelect,
}: {
  onSelect: (shortcode: IShortcode) => void
}) {
  const fetchShortcodes = useFetchGuestCommunicationShortcodes({})
  const { t, langKeys } = useTranslate()
  const shortcodes = fetchShortcodes.data

  return (
    <DropdownButton
      displayValue={t(langKeys.guest_communication_add_short_code)}
      variant="minimal"
      placement="bottom-end"
    >
      {({ closeMenu }) => (
        <MenuList>
          {/* FIXME: PD-1877 handle errors */}
          {fetchShortcodes.isLoading && (
            <MenuItem disabled>
              <MCircularProgress
                size={15}
                style={{
                  marginRight: spacing.M,
                }}
              />
              <MText>{t(langKeys.loading)}</MText>
            </MenuItem>
          )}
          {shortcodes?.map((shortcode) => (
            <MenuItem
              onClick={() => {
                onSelect(shortcode)
                closeMenu()
              }}
              key={shortcode.shortcode}
            >
              {shortcode.shortcode_name}
            </MenuItem>
          ))}
        </MenuList>
      )}
    </DropdownButton>
  )
}
